import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import MauticForm from '../../components/ui/Forms/MauticForm/MauticForm';
import Seo from '../../components/utils/Seo';
import { Direction } from '../../globals';
import './newsletter.scss';

interface NewsletterProps {
    data: {
        file: FluidImage;
    };
}

const Newsletter: React.FC<NewsletterProps> = (props: NewsletterProps) => {
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Newsletter"
                url="newsletter/"
                description="Join AI Summer's community by subscribing to our Newsletter"
            />
            <PageSection className="newsletter">
                <Container>
                    <Group size={2}>
                        <Block direction={Direction.vertical}>
                            <h1>
                                Our Deep Learning content straight to your inbox
                            </h1>
                            <h2>
                                Expand your AI knowledge with our weekly
                                articles and stay updated on the latest trends
                                in the field.
                            </h2>
                            <MauticForm
                                formId="1"
                                formName="registrationfrompost"
                                isDark={false}
                            />
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="newsletter__image"
                        >
                            <Img
                                fluid={props.data.file.childImageSharp.fluid}
                                alt='AI Summer Newsletter'
                            ></Img>
                        </Block>
                    </Group>
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const gnewsletterQuery = graphql`
    {
        file(relativePath: { eq: "newsletter.png" }) {
            childImageSharp {
                fluid {
                    aspectRatio
                    src
                    srcSet
                    sizes
                }
            }
        }
    }
`;

export default Newsletter;
